import { generalInfoKeys } from "./channelkeys";

export const validarRut = (rut = "") => {
  let value = rut.replace(/[^A-Z0-9]/gi, "");

  var cuerpo = value.slice(0, -1);
  var dv = value.slice(-1).toUpperCase();

  var suma = 0;
  var multiplo = 2;

  for (var i = 1; i <= cuerpo.length; i++) {
    var index = multiplo * value.charAt(cuerpo.length - i);

    suma += index;

    if (multiplo < 7) {
      multiplo += 1;
    } else {
      multiplo = 2;
    }
  }

  var dvEsperado = 11 - (suma % 11);

  // Casos Especiales (0 y K)
  dv = dv == "K" ? 10 : dv;
  dv = dv == 0 ? 11 : dv;

  // Validar que el Cuerpo coincide con su Dígito Verificador
  return !(dvEsperado != dv);
};

export const validarRutFormat = (rut = "") => {
  const pattern = /^\d{1,2}\d{3}\d{3}[0-9kK]{1}$/;
  const patternTwo = /^\d{1,2}\.\d{3}\.\d{3}[-][0-9kK]{1}$/;

  const test1 = pattern.test(rut);
  const test2 = patternTwo.test(rut);

  return test1 || test2;
};

export const validarFecha = (fecha = "") => {
  const regex = /^\d{2}\/\d{2}\/\d{4}$/; // Formato dd/mm/yyyy numerico
  if (fecha.match(regex) === null) {
    return false;
  }

  const [day, month, year] = fecha.split("/");
  const isoFormattedStr = `${year}-${month}-${day}`;
  const date = new Date(isoFormattedStr);
  const timestamp = date.getTime();

  if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
    return false;
  }

  return date.toISOString().startsWith(isoFormattedStr);
};

export const validarEmail = (email = "") => {
  // Formato example@domain.ext
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let isValid = regex.test(email);
  return isValid;
};

export const validarNumerico = (num = "") => {
  // Formato solo numeros
  const pattern = /^\d+$/;
  return pattern.test(num);
};

export const validarImagen = (file = null) => {
  const allowedFormats = ["image/jpeg", "image/jpg", "image/png"];
  const isFormatValid = file ? allowedFormats.includes(file?.type) : true;
  return (
    isFormatValid ||
    "Formato de archivo no válido. Solo se permiten imágenes JPEG y PNG."
  );
};

export const validarPermisoTipoCanal = (
  num = 1,
  channelType = generalInfoKeys.channelType.typeLevels[3]
) => {
  let condition,
    sentence1 = channelType == generalInfoKeys.channelType.typeLevels[1],
    sentence2 = channelType == generalInfoKeys.channelType.typeLevels[2],
    sentence3 = channelType == generalInfoKeys.channelType.typeLevels[3];
  switch (num) {
    case 1:
      condition = sentence1;
      break;
    case 2:
      condition = sentence1 || sentence2;
      break;
    case 3:
      condition = sentence2 || sentence3;
      break;
    case 4:
      condition = sentence3;
      break;

    default:
      condition = false;
      break;
  }
  return condition;
};

export default {
  validarRut,
  validarRutFormat,
  validarFecha,
  validarEmail,
  validarNumerico,
  validarImagen,
};
