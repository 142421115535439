<template>
  <v-container class="not-extended">
    <v-card>
      <v-data-table v-bind="{ ...bind.table, ...tableBind }" v-on="tableOn">
        <template v-slot:top>
          <div class="d-flex pa-3">
            <v-text-field
              v-model="search"
              v-bind="bind.search"
              v-on="searchOn"
            ></v-text-field>
            <v-divider v-bind="bind.divider"></v-divider>
            <v-spacer></v-spacer>
            <v-btn v-bind="bind.refresh" v-on="on.refresh">
              <v-icon>{{ icons.refresh }}</v-icon>
            </v-btn>
            <v-btn v-bind="bind.new" v-on="on.new">
              {{ labels.new }} <v-icon right>mdi-shield-plus</v-icon>
            </v-btn>
          </div>
        </template>

        <template v-slot:[`item.tipo_documento`]="{ item }">
          {{ item.tipo_documento }} - {{ item.numero_documento }}
        </template>

        <template v-slot:[`item.eliminado`]="{ item }">
          <v-chip
            class="ma-2"
            small
            :color="item.eliminado ? 'grey lighten-2' : 'primary'"
          >
            <v-icon x-small class="mr-1">mdi-checkbox-blank-circle</v-icon>
            {{ item.eliminado ? "Inactivo" : "Activo" }}
          </v-chip>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left color="success">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="success"
                x-small
                text
                fab
                @click="activateItem(item)"
                v-on="on"
                v-bind="attrs"
                :disabled="!item.eliminado"
              >
                <v-icon small> mdi-checkbox-marked-circle </v-icon>
              </v-btn>
            </template>
            <span>Activar nuevamente a {{ item[keys.description] }}</span>
          </v-tooltip>

          <v-tooltip left color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                x-small
                text
                fab
                @click="selectItem(item)"
                v-on="on"
                v-bind="attrs"
              >
                <v-icon small> {{ icons.edit }} </v-icon>
              </v-btn>
            </template>
            <span>Editar la información de {{ item[keys.description] }}</span>
          </v-tooltip>
          <v-tooltip left color="error">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="error"
                x-small
                text
                fab
                @click="deleteItem(item)"
                v-on="on"
                v-bind="attrs"
                :disabled="item.eliminado"
              >
                <v-icon small> {{ icons.delete }} </v-icon>
              </v-btn>
            </template>
            <span>Eliminar {{ item[keys.description] }} del listado</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <!-- MODAL CREAR Y EDITAR -->
    <v-dialog
      v-model="dialog"
      @click:outside="closeForm()"
      max-width="500px"
      scrollable
      :persistent="loadingForm"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ labels.formTitle }}</span>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            x-small
            fab
            text
            @click="closeForm()"
            :disabled="loadingForm"
          >
            <v-icon>{{ icons.close }}</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" class="pt-4">
            <v-text-field
              dense
              v-model="form.corredor_descripcion"
              label="Nombre del corredor"
              :rules="[(v) => !!v || 'El campo nombre es requerido.']"
            ></v-text-field>
            <v-divider class="mb-6"></v-divider>
            <v-row no-gutters>
              <v-col cols="4" class="pr-1">
                <v-text-field
                  dense
                  v-model="form.tipo_documento"
                  label="Tipo de documento"
                  :rules="[
                    (v) => !!v || 'El campo tipo de documento es requerido.',
                  ]"
                ></v-text-field>
              </v-col>
              <v-col cols="8" class="pl-1">
                <v-text-field
                  dense
                  v-model="form.numero_documento"
                  label="Numero de documento"
                  :rules="[
                    (v) => !!v || 'El campo número de documento es requerido.',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="mb-6"></v-divider>
            <h4 class="subtitle-1 text-center">Póliza</h4>
            <v-row no-gutters>
              <v-col cols="12" md="6" class="pr-1">
                <v-text-field
                  dense
                  v-model="form.poliza_garantia"
                  label="Garantía"
                  :rules="[(v) => !!v || 'El campo garantía es requerido.']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pl-1">
                <v-text-field
                  dense
                  v-model="form.poliza_responsabilidad"
                  label="Responsabilidad"
                  :rules="[
                    (v) => !!v || 'El campo responsabilidad es requerido.',
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="mb-6"></v-divider>
            <v-text-field
              label="Diversificación de la producción"
              dense
              :rules="[(v) => !!v || 'El campo diversificación es requerido.']"
              v-model="form.diversificacion_produccion"
            ></v-text-field>

            <v-file-input
              @change="actualiza_logo = true"
              counter
              truncate-length="18"
              :rules="rules.img"
              accept="image/jpeg, image/jpg, image/png"
              dense
              label="Adjuntar logo"
              v-model="input_file"
            ></v-file-input>

            <div class="d-flex elevation-1 justify-center" v-if="imgUrl !== ''">
              <v-img :src="imgUrl" height="120" contain />
            </div>

            <v-file-input
              @change="actualiza_firma = true"
              counter
              truncate-length="18"
              :rules="rules.img"
              accept="image/jpeg, image/jpg, image/png"
              dense
              clearable
              label="Adjuntar firma"
              v-model="input_file_2"
            ></v-file-input>

            <div
              class="d-flex elevation-1 justify-center"
              v-if="imgUrlFirma !== ''"
            >
              <v-img :src="imgUrlFirma" height="120" contain />
            </div>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="handlerSave()"
            :loading="loadingForm"
          >
            {{ selected ? "Actualizar" : "Guardar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <confirm-modal :ref="refs.confirm" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PageMixin from "@/Mixins/SetPageMixin.js";
import PaginationMixin from "@/Mixins/PaginationMixin.js";
import { toBase64, getFileExtension } from "@/store/resources/tools.js";
import { validarImagen } from "@/store/resources/validate.js";
import axios from "axios";
export default {
  name: "InsuranceBrokersMaintainer",
  mixins: [PageMixin, PaginationMixin],
  data: () => ({
    actualiza_logo: false,
    actualiza_firma: false,
    input_file: undefined,
    input_file_2: undefined,
    imgUrl: "",
    imgUrlFirma: "",
    mensaje_input_requeridos: "",
    boolean_input_requerido: false,
    firmaEdit: "",
    logoEdit: "",
    form: {
      corredor_descripcion: "",
      tipo_documento: "RUT",
      numero_documento: "",
      poliza_garantia: "",
      poliza_responsabilidad: "",
      diversificacion_produccion: "",
      actualiza_logo: false,
      actualiza_firma: false,
      logo: {
        mime: "",
        nombre_archivo: "",
        archivo: "",
      },
      firma: {
        mime: "",
        nombre_archivo: "",
        archivo: "",
      },
    },
    labels: {
      new: "Nuevo corredor",
      search: "Buscar un corredor de seguro",
      formTitle: "Formulario corredor de seguros",
      delete: "Eliminar Elemento",
      deleteDescription:
        "Está seguro que desea eliminar el corredor de seguros",
    },
  }),
  computed: {
    ...mapGetters("NewProduct", ["brokersList"]),

    headers() {
      return [
        {
          text: "Descripción",
          align: "start",
          sortable: false,
          value: "corredor_descripcion",
        },
        {
          text: "Documento",
          align: "start",
          sortable: false,
          value: "tipo_documento",
        },
        {
          text: "Estado",
          align: "center",
          sortable: false,
          value: "eliminado",
        },
        { text: "Actions", value: "actions", align: "end", sortable: false },
      ];
    },
    rules() {
      return {
        img: [
          (file) =>
            !!file ||
            !!this.selected ||
            "Debe cargar una imagen para continuar.",
          (file) => validarImagen(file),
        ],
      };
    },
    keys() {
      return {
        id: "_id",
        description: "corredor_descripcion",
      };
    },
    items() {
      return this.brokersList;
    },
  },
  methods: {
    ...mapActions("NewProduct", [
      "CreateInsuranceBroker",
      "UpdateInsuranceBroker",
      "DeleteInsuranceBroker",
      "ActivateInsuranceBroker",

      "REQUEST_INSURANCE_BROKER",
    ]),

    setup() {
      this.setupData();
    },
    setupData() {
      this.loading = true;
      return new Promise((resolve) => {
        this.REQUEST_INSURANCE_BROKER({
          ...this.pagination,
          filter: this.search,
        })
          .then((pagination) => {
            this.setPaginationFromResponse(pagination);
            resolve();
          })
          .finally(() => (this.loading = false));
      });
    },
    setPaginationFromResponse(pagination = null) {
      if (pagination) {
        this.pagination.page = pagination?.pagina ?? 1;
        this.pagination.pages = pagination?.paginas ?? 1;
        this.pagination.objects = pagination?.total ?? 0;
      }
    },
    handlerSave() {
      if (this.$refs.form.validate()) {
        if (this.selected) {
          this.update();
        } else {
          this.saveItem();
        }
      }
    },
    async saveItem() {
      this.loadingForm = true;
      let extensionLogo = "";
      let extensionFirma = "";
      let extensiones_admitidas = ["png", "jpg", "jpeg"];
      extensionLogo = getFileExtension(this.input_file.name);
      extensionFirma = getFileExtension(this.input_file_2.name);
      if (
        !extensiones_admitidas.includes(extensionLogo) ||
        !extensiones_admitidas.includes(extensionFirma)
      ) {
        this.mensaje_input_requeridos = "Extensión de archivo no admitida";
        this.boolean_input_requerido = true;
        this.loadingForm = false;
        return [false, ""];
      }
      this.form.logo.nombre_archivo = this.input_file.name;
      this.form.logo.mime = extensionLogo;
      this.form.firma.nombre_archivo = this.input_file_2.name;
      this.form.firma.mime = extensionFirma;

      let base64 = await toBase64(this.input_file);
      let base64_2 = await toBase64(this.input_file_2);

      this.form.logo.archivo = base64;
      this.form.firma.archivo = base64_2;

      this.CreateInsuranceBroker(this.form)
        .then((message) => {
          this.snackbar(message, "success");
          this.closeForm();
        })
        .catch((message) => this.snackbar(message, "error"))
        .finally(() => (this.loadingForm = false));
    },
    async update() {
      this.loadingForm = true;
      let extensiones_admitidas = ["png", "jpg", "jpeg"];
      if (this.actualiza_logo) {
        let extensionLogo = "";
        extensionLogo = getFileExtension(this.input_file.name);
        if (!extensiones_admitidas.includes(extensionLogo)) {
          this.mensaje_input_requeridos = "Extensión de archivo no admitida";
          this.boolean_input_requerido = true;
          this.loadingForm = false;
          return [false, ""];
        }
        this.form.logo.nombre_archivo = this.input_file.name;
        this.form.logo.mime = extensionLogo;
        let base64 = await toBase64(this.input_file);
        this.form.logo.archivo = base64;
        this.form.actualiza_logo = true;
      }
      if (this.actualiza_firma) {
        let extensionFirma = "";
        extensionFirma = getFileExtension(this.input_file_2.name);
        if (!extensiones_admitidas.includes(extensionFirma)) {
          this.mensaje_input_requeridos = "Extensión de archivo no admitida";
          this.boolean_input_requerido = true;
          this.loadingForm = false;
          return [false, ""];
        }
        this.form.firma.nombre_archivo = this.input_file_2.name;
        this.form.firma.mime = extensionFirma;
        let base64_2 = await toBase64(this.input_file_2);
        this.form.firma.archivo = base64_2;
        this.form.actualiza_firma = true;
      }
      const payload = {
        id: this.selected[this.keys.id],
        data: this.form,
      };
      this.UpdateInsuranceBroker(payload)
        .then((message) => {
          this.snackbar(message, "success");
          this.closeForm();
        })
        .catch((message) => this.snackbar(message, "error"))
        .finally(() => (this.loadingForm = false));
    },
    async callImgLogo(url) {
      let { data } = await axios.get(url);
      this.imgUrl = data.data.url;
    },
    async callImgFirma(url) {
      let { data } = await axios.get(url);
      this.imgUrlFirma = data.data.url;
    },
    activateItem(item) {
      this.selected = item;
      this.loading = true;
      this.ActivateInsuranceBroker(item[this.keys.id])
        .then((message) => this.snackbar(message, "success"))
        .catch((message) => this.snackbar(message, "error"))
        .finally(() => (this.loading = false));
    },
    deleteItem(item) {
      this.$refs[this.refs.confirm]
        .show({
          title: this.labels.delete,
          description: this.labels.deleteDescription,
        })
        .then((answer) => {
          if (answer) {
            this.loadingForm = true;
            this.loading = true;
            this.DeleteInsuranceBroker(item[this.keys.id])
              .then((message) => {
                this.snackbar(message, "success");
              })
              .catch((message) => this.snackbar(message, "error"))
              .finally(() => {
                this.loadingForm = false;
                this.loading = false;
              });
          }
        });
    },
    closeForm() {
      this.dialog = false;

      this.form.corredor_descripcion = "";
      this.form.tipo_documento = "RUT";
      this.form.numero_documento = "";
      this.form.poliza_garantia = "";
      this.form.poliza_responsabilidad = "";
      this.form.diversificacion_produccion = "";
      this.input_file = [];
      this.input_file_2 = [];
      this.imgUrl = "";
      this.imgUrlFirma = "";
      this.boolean_input_requerido = false;

      this.selected = null;
    },
    selectItem(item) {
      this.selected = item;
      this.form.title = this.labels.formEdit;
      this.firmaEdit = item?.firma?.url != null ? item?.firma?.url : "";
      this.logoEdit = item?.logo?.url != null ? item?.logo?.url : "";

      this.form.corredor_descripcion = item.corredor_descripcion || "";
      this.form.tipo_documento = item.tipo_documento || "RUT";
      this.form.numero_documento = item.numero_documento || "";
      this.form.poliza_garantia = item.poliza_garantia || "";
      this.form.poliza_responsabilidad = item.poliza_responsabilidad || "";
      this.form.diversificacion_produccion =
        item.diversificacion_produccion || "";

      if (item.logo != null) {
        this.callImgLogo(this.logoEdit);
      }
      if (item.firma != null) {
        this.callImgFirma(this.firmaEdit);
      }

      this.dialog = true;
    },
  },
};
</script>
